// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #f5f5f5;
}

.App {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #f5f5f5;
}

.img-responsive {
  max-width: 80%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":["body, html {\n  padding: 0;\n  margin: 0;\n  height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.App {\n  padding: 0;\n  margin: 0;\n  height: 100vh;\n  background-color: #f5f5f5;\n}\n\n.img-responsive {\n  max-width: 80%;\n  height: auto;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
