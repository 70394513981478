import axios from 'axios';

const configureAxios = (token, actualizarToken) => {

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  // Configura el interceptor para incluir el token en todas las solicitudes
  // Configura el token de autenticación
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  // Interceptor de respuesta para manejar la expiración del token
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Token expirado o no válido
        actualizarToken(null); // Limpiar el token
        // Redirigir al usuario a la página de login si es necesario
        // window.location.href = '/login'; // descomentar si quieres redirigir al login
      }
      return Promise.reject(error);
    }
  ); 


};

export default configureAxios;
