import React from 'react';
import Box from '@mui/material/Box';

function PageContent({ children }) {
  return (
    <Box
    sx={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        mt: 2,
        // border: 2,
        // borderColor: 'grey.500',
        // borderRadius: 5,
        pb: 5,
        minHeight: '100vh',
        // backgroundColor: 'white'
    }}
    >
    {children}
    </Box>
  );
}

export default PageContent;
