import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useNavigate  } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { login } from '../../services/authService';
import { getUserInfo } from "../../services/userService";
import useStore from '../../store/store';
import { useEffect } from 'react';


export default function Login() {

    const { setToken, setNivelAcceso, clearSession, setUsername } = useStore();
    const navigate = useNavigate ();   

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let username = data.get("username");
        let password = data.get("password");

        try {
            // Limpia el estado de la sesión antes de intentar iniciar sesión
            clearSession();

            let response = await login(username, password);
            
            if (response.status === 200 && response.data.token) {
                const newToken = response.data.token;
                if (newToken) {
                    // Actualiza el token en el estado de Zustand
                    setToken(newToken);
                    
                    // Obtener información del usuario
                    let userInfo = await getUserInfo(newToken);
                    setNivelAcceso(userInfo.id_nivel_acceso);
                    setUsername(userInfo.usuario);

                    // Redirige al usuario a la página de inicio
                    navigate('/home');
                    toast.success('¡Bienvenido al Sistema del MFC!');
                }
            }else{
                toast.error('¡Usuario o contraseña incorrecto!');
            }

        } catch (error) {
            // Manejo de errores en caso de que la autenticación falle
            console.error('Error al obtener token de autenticación', error);
            toast.error('¡Usuario o contraseña incorrecto!');
        }

    };


    return (
        <Container component="main" maxWidth="lg"
            sx={{
                backgroundColor: 'grey.100'
            }}>
            <Box
                sx={{
                marginTop: 12,
                }}
            >
                <Grid container justifyContent="center">
                <CssBaseline />
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    >
                    <Typography component="h1" variant="h5">
                        Sistema MFC
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Ingresar usuario"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Ingresar contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        />
                        {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Recordar contraseña"
                        /> */}
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        >
                        Ingresar
                        </Button>
                        {/* <Grid container justifyContent="center">
                        <Grid item>
                            <Link href="/recuperar-clave" variant="body2">
                                Recuperar contraseña
                            </Link>
                        </Grid>
                        </Grid> */}
                    </Box>
                    </Box>
                </Grid>
                </Grid>
            </Box>
        </Container>
    );
}
