import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function BreadcrumbsComponent({ breadcrumbs }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Stack spacing={2}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs.map((breadcrumb, index) => (
            <Link key={index} underline="hover" color="inherit" href={breadcrumb.href}>
              {breadcrumb.label}
            </Link>
          ))}
        </Breadcrumbs>
      </Stack>
    </Box> 
  );
}

export default BreadcrumbsComponent;
