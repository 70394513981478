import React from 'react';
import Typography from '@mui/material/Typography';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getMatrimonio } from '../../services/matrimoniosService';
import PaginationTable from '../../components/tables/PaginationTable';
import { convertDate } from '../../utils/utils';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import { getCursos, descargarExcelCursos } from '../../services/cursoService';
import { registrarCursoMatrimonio } from '../../services/cursoService';
import toast from 'react-hot-toast';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip from '@mui/material/Tooltip';
import { getToday } from '../../utils/utils';


function AsignarCursoMatrimonio() {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const { idMatrimonio } = location.state || {};
    const [matrimonio, setMatrimonio] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]); // Estado para las filas filtradas
    const [search, setSearch] = useState(''); // Estado para el valor de búsqueda
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [maxWidth, setMaxWidth] = React.useState('md');
    const [selectedNivel, setSelectedNivel] = React.useState('');
    const [selectedCurso, setSelectedCurso] = React.useState('');
    const [cursos, setCursos] = React.useState([]);
    const [lugarCurso, setlugarCurso] = React.useState('');
    const [fechaDesde, setFechaDesde] = React.useState(getToday());
    const [fechaHasta, setFechaHasta] = React.useState(getToday());
    const [disertante, setDisertante] = React.useState('');

    const columns = [
        { id: 'nivel', label: 'Nivel', minWidth: 50 },
        { id: 'nombre_curso', label: 'Curso', minWidth: 100 },
        { id: 'fecha_desde', label: 'Fecha desde', minWidth: 50},
        { id: 'fecha_hasta', label: 'Fecha hasta', minWidth: 50},
        { id: 'lugar', label: 'Lugar', minWidth: 100},
        { id: 'disertante', label: 'Disertante', minWidth: 100}
    ]; 

    const nivelCiclo = [
        { id: 1, ciclo: 'CBF' },
        { id: 2, ciclo: 'Dirigencial' },
        { id: 3, ciclo: 'Profundización' },
    ];

    useEffect(() => {
        
        async function fetchData() {
            const matrimonioData = await fetchMatrimonioData(idMatrimonio);
            const cursosData = await fetchCursos();
            setCursos(cursosData);
        }
        
        fetchData();    
    
    }, [idMatrimonio]);

    useEffect(() => {
        // Función para filtrar las filas basada en la búsqueda
        const filterRows = () => {
            const lowercasedSearch = search.toLowerCase();
            const filtered = rows.filter(row =>
              (row.nombre_curso?.toLowerCase().includes(lowercasedSearch) || 
              row.nivel?.toLowerCase().includes(lowercasedSearch) || 
              row.fecha_desde?.includes(lowercasedSearch) || 
              row.fecha_hasta?.includes(lowercasedSearch)) 
            );
            setFilteredRows(filtered);
          };

        if (search === '') {
            setFilteredRows(rows); // Reset a la lista total cuando la búsqueda está vacía
        } else {
            filterRows();
        }

    }, [search, rows]);


    const fetchMatrimonioData = async (id) => {
        try {
          const data = await getMatrimonio(id);
          setMatrimonio(data);

          const mappedRows = data.estudio_cursado_matrimonios.map(curso => ({
            id_matrimonio: curso.id_matrimonio,
            nombre_curso: curso.cursos.nombre_curso,
            nivel: curso.cursos.nivel_ciclo.ciclo,
            fecha_desde: convertDate(curso.fecha_desde),
            fecha_hasta: convertDate(curso.fecha_hasta),
            lugar: curso.lugar,
            disertante: curso.disertante
          }));
          setRows(mappedRows);
          setFilteredRows(mappedRows);
        } catch (error) {
          console.error('Error al obtener los datos del matrimonio:', error);
        }
      }; 

    const handleConfirmar = async () => {

        try {

            if (!selectedNivel || !selectedCurso || !lugarCurso || !fechaDesde || !fechaHasta || !disertante) {
                toast.error('Todos los campos son obligatorios.');
                return;
            }

            const response = await registrarCursoMatrimonio({
                id_matrimonio: idMatrimonio,
                id_curso: selectedCurso,
                lugar: lugarCurso,
                disertante: disertante,
                fecha_desde: fechaDesde,
                fecha_hasta: fechaHasta
            });
    
    
            setOpen(false);
            setSelectedNivel('');
            setSelectedCurso('');
            setlugarCurso('');
            setFechaDesde(new Date().toISOString().slice(0,10));
            setFechaHasta(new Date().toISOString().slice(0,10));
            setDisertante('');

            // Recargar los datos del matrimonio y sus cursos
            await fetchMatrimonioData(idMatrimonio);

            toast.success('Curso registrado correctamente.');
            
        } catch (error) {
            console.error('Error al registrar el curso:', error);
            toast.error('Error al registrar el curso.');
        }

    };

    const handleClose = () => {
    setOpen(false);
    };  

    const handleClickOpen = (idMatrimonio) => {
        setOpen(true);
    };

    const handleNivelChange = (event) => {
        setSelectedNivel(event.target.value);
    };

    const handleCursoChange = (event) => {
        setSelectedCurso(event.target.value);
    };

    const fetchCursos = async () => {
        try {
            const data = await getCursos();
            return data;
        } catch (error) {
            console.error('Error al obtener los datos de los cursos:', error);
        }
    }

    const handleDownloadExcel = async () => {
        try {
            const response = await descargarExcelCursos(idMatrimonio);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = url;
            link.download = 'cursos-matrimonio.xlsx';
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            toast.error('Error al descargar el archivo.');
        }
    };
    

    return (
    <PageWrapper>

        <PageTitle title="Cursos del Matrimonio"/>

        <Typography variant="h5" align="center">
            <b>Matrimonio:</b> {matrimonio?.ella_nombres} {matrimonio?.ella_apellidos} y {matrimonio?.el_nombres} {matrimonio?.el_apellidos}
        </Typography>

        <PageContent>

            {/* Sección filtros */}
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: 1,
                borderColor: 'grey.300',
                borderRadius: 1,
                p: 2,
                backgroundColor: 'white',
                mb: 2,
                width: '100%'
            }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            label="Buscar por curso, nivel o fecha"
                            variant="outlined"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)} 
                        />

                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Tooltip title="Cargar nuevo curso" placement="top">
                            <Button 
                            variant="contained" 
                            size="large" 
                            style={{ height: '100%', backgroundColor: 'green'}} 
                            onClick={ handleClickOpen } ><AddCircleOutlineIcon /></Button>
                        </Tooltip>

                        <Tooltip title="Descargar planilla de cursos realizados" placement="top">
                            <Button 
                                variant="contained" 
                                size="large" 
                                style={{ height: '100%', marginLeft: '10px', backgroundColor: 'blue' }} 
                                onClick={ handleDownloadExcel } >
                                <ArrowCircleDownIcon />
                            </Button>
                        </Tooltip>

                    </Grid>

                </Grid>
            </Box>

            <PaginationTable
                columns={columns}
                rows={filteredRows}
            />

        </PageContent>

        {/* Sección para registrar curso realizado por matrimonio*/}
        <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '500px'} }}>
            
          <DialogTitle>Registrar curso para matrimonio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Inserte los datos sobre el curso realizado por el matrimonio.
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <BasicSelect
                            label="Nivel del curso"
                            labelId="simple-select-label"
                            id="simple-select"
                            value={selectedNivel}
                            required
                            onChange={handleNivelChange}
                            options={nivelCiclo.map((nivel) => ({
                            label: nivel.ciclo,
                            value: nivel.id,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <BasicSelect
                            label="Curso"
                            labelId="simple-select-label"
                            id="simple-select"
                            value={selectedCurso}
                            required
                            onChange={handleCursoChange}
                            options={cursos.map((curso) => ({
                            label: curso.nombre_curso,
                            value: curso.id_curso,
                            }))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="fecha-desde"
                            label="Fecha desde"
                            type="date"
                            defaultValue={fechaDesde}
                            required
                            onChange={(event) => setFechaDesde(event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="fecha-hasta"
                            label="Fecha hasta"
                            type="date"
                            defaultValue={fechaHasta}
                            onChange={(event) => setFechaHasta(event.target.value)}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="lugar"
                            label="Lugar"
                            type="text"
                            value={lugarCurso}
                            required
                            onChange={(event) => setlugarCurso(event.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            id="disertante"
                            label="Disertante"
                            type="text"
                            value={disertante}
                            required
                            onChange={(event) => setDisertante(event.target.value)}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>


                </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmar} >Confirmar</Button>
          </DialogActions>
        </Dialog>

    </PageWrapper>
    );
}

export default AsignarCursoMatrimonio;
