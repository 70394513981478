import React from 'react';
import Typography from '@mui/material/Typography';

function PageTitle({ title }) {
  return (
    <Typography variant="h5" align="center">
      {title}
    </Typography>
  );
}

export default PageTitle;
