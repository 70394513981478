import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getGrupos = async (id_base = null, id_grupo = null) => {
    
    try {

        const response = await axios.get(`${API_URL}/grupos/`, {
            params: { 
                pageSize: 10000,
                id_base,
                id_grupo
            }
          });

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const createGrupo = async (id_base, nombre_grupo, estado) => {
    try {
        const response = await axios.post(`${API_URL}/grupos/create/`, {
            id_base,
            nombre_grupo,
            estado
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateGrupo = async (id_grupo, id_base, nombre_grupo, estado) => {
    try {
        const response = await axios.put(`${API_URL}/grupos/update/`, {
            id_grupo,
            id_base,
            nombre_grupo,
            estado
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}