import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getBases = async (id_diocesis = null, id_base = null) => {
    try {
        const response = await axios.get(`${API_URL}/bases/`, {
            params: { 
                pageSize: 1000,  
                id_diocesis: id_diocesis,
                id_base: id_base,
            },
          });

        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export const createBase = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/bases/create`, data);
        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateBase = async (data) => {
    try {
        const response = await axios.put(`${API_URL}/bases/update`, data);
        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}