import React, { useEffect } from 'react';
import './App.css';
import { AuthRoutes } from './routes/auth/AuthRoutes';
import toast, { Toaster } from 'react-hot-toast';
import configureAxios from './axiosConfig';
import useStore from '../src/store/store';

function App() {
  const { token, setToken, clearSession } = useStore();

  // Configuración de Axios con el interceptor
  useEffect(() => {
    configureAxios(token, setToken);
  }, [token, setToken]);

  return (
    <div className="App">
      <Toaster/>
      <AuthRoutes />
    </div>
  );
}

export default App;
