import React from 'react';
import Typography from '@mui/material/Typography';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { getAllUsers, assignRol } from '../../services/userService';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BasicSelect from '../../components/basicSelect/BasicSelect';


function Administracion() {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [open, setOpen] = useState(false);
  const [rol, setRol] = useState('');
  const [matrimonioId, setMatrimonioId] = useState('');
  const [matrimonios, setMatrimonios] = useState([]);
  const [search, setSearch] = useState('');


  const columns = [
    { id: 'id_matrimonio', label: 'ID', minWidth: 10 },
    { id: 'matrimonio', label: 'Matrimonio', minWidth: 150 },
    { id: 'el_cedula', label: 'CI Esposo', minWidth: 60},
    { id: 'diocesis', label: 'Diócesis', minWidth: 50},
    { id: 'base', label: 'Base', minWidth: 200},
    { id: 'grupo', label: 'Grupo', minWidth: 50},
    { id: 'rol', label: 'Rol', minWidth: 20},
  ];  

  
  useEffect(() => {
    async function fetchData() {
      try {

        await fetchAllUsers();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []); 

  useEffect(() => {
    // Función para filtrar las filas basada en la búsqueda
    const filterRows = () => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = rows.filter(row =>
          (
            row.matrimonio?.toLowerCase().includes(lowercasedSearch) ||
            row.el_cedula?.toString().toLowerCase().includes(lowercasedSearch) || 
            row.diocesis?.toLowerCase().includes(lowercasedSearch) ||
            row.base?.toLowerCase().includes(lowercasedSearch) ||
            row.grupo?.toLowerCase().includes(lowercasedSearch) ||
            row.rol?.toLowerCase().includes(lowercasedSearch)
          ) 
        );
        setRows(filtered);
      };

    if (search === '') {
        fetchAllUsers();
    } else {
        filterRows();
    }

  }, [search, rows]); 

  async function fetchAllUsers() {
    const users = await getAllUsers();
    setRows(users);
  }

  const handleClickOpen = (matrimonioId) => {
    setMatrimonioId(matrimonioId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleChangeRol = async () => {
    console.log('Nuevo rol: ', rol);

    try {

      if (!matrimonioId) {
        toast.error('No se ha seleccionado un matrimonio');
        return;
      }
      const response = await assignRol(matrimonioId, rol);
      console.log('Respuesta: ', response);
      toast.success('Rol asignado correctamente');
    } catch (error) {
      console.error('Error al asignar rol:', error);
      toast.error('Error al asignar rol');
    }

    setOpen(false);
    setRol('');
    await fetchAllUsers();
  };

  return (
    <PageWrapper>

      <PageTitle title="Asignación de rol de usuario"/>

      <PageContent>

      {/* Buscador y filtros */}
      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
        }}
        >
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                    fullWidth
                    label="Buscar matrimonio"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} 
                />
            </Grid>
          </Grid>
        </Box>  

        <PaginationTable
          columns={columns}
          rows={rows || []}
          renderActions={(row) => (
            <>
              <Tooltip title="Asignar rol" placement="top">
                <VerifiedUserIcon onClick={() => handleClickOpen(row.id_matrimonio)}/>
              </Tooltip>
            </>
          )}
        />

      </PageContent>

      {/* Sección para dar de baja matrimonio*/}
      <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '500px'} }}>
            
          <DialogTitle>Asignar rol a usuario</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccionar rol a asignar
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                    <BasicSelect
                      label="Rol"
                      labelId="select-rol"
                      id="select-rol"
                      value={rol}
                      onChange={(event) => setRol(event.target.value)}
                      options={[
                        { label: 'Dpto. Seguimiento Base Parroquial', value: 4 },
                        { label: 'Dpto. Seguimiento Diocesis', value: 3 },
                        { label: 'Dpto. Formación Diocesis', value: 7 },
                        { label: 'Dpto. Formación Base Parroquial', value: 8 },
                        { label: 'Dpto. Comunicación Diocesis', value: 9 },
                        { label: 'Dpto. Comunicación Base Parroquial', value: 10 },
                        { label: 'Dpto. Servicio a la Comunidad Diocesis', value: 11 },
                        { label: 'Dpto. Servicio a la Comunidad Base Parroquial', value: 12 },
                        { label: 'Dpto. Espiritualidad Diocesis', value: 13 },
                        { label: 'Dpto. Espiritualidad Base Parroquial', value: 14 },
                        { label: 'Sin rol', value: 0 },
                      ]}
                      sx={{ width: '100%', mt: 2 }}
                    />
                  </Grid>
                <Grid item xs={12} mt={2}>

                </Grid>
              </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleChangeRol}>Confirmar</Button>
          </DialogActions>
        </Dialog>

    </PageWrapper>
  );
}

export default Administracion;
