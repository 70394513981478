import React from 'react';
import Typography from '@mui/material/Typography';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function RecoverPassowrd() {
  const navigate = useNavigate ();

  const openLogin = () => {
    navigate('/');
  }

  return (
    <PageWrapper>

      <PageTitle title="Recuperar contraseña"/>

      <PageContent>
        <Typography variant="h5" align="center">
          Contenido
        </Typography>
        {/* Boton volver al login */}
        <Button onClick={openLogin}>
          Volver
        </Button>

      </PageContent>

    </PageWrapper>
  );
}

export default RecoverPassowrd;
