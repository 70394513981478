import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getDiocesis = async (id_diocesis = null) => {
    
    try {

        const response = await axios.get(`${API_URL}/diocesis/`, {
            params: { 
                pageSize: 20,  
                id_diocesis: id_diocesis
            }
          });

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const createDiocesis = async (diocesisName, nomenclatura, statusDiocesis) => {
    try {
        let params = {
            nombre_diocesis: diocesisName,
            nomenclatura: nomenclatura,
            status: statusDiocesis
        };
        const response = await axios.post(`${API_URL}/diocesis/create`, params);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateDiocesis = async (id_diocesis, diocesisName, nomenclatura, statusDiocesis) => {
    try {
        let params = {
            id_diocesis: id_diocesis,
            nombre_diocesis: diocesisName,
            nomenclatura: nomenclatura,
            status: statusDiocesis
        };
        const response = await axios.put(`${API_URL}/diocesis/update`, params);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
