import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageContent from '../../components/pageContent/PageContent';
import PageTitle from '../../components/pageContent/PageTitle';
import Autocomplete from '@mui/lab/Autocomplete';
import { getDiocesis } from '../../services/diocesisService';
import { getBases } from '../../services/baseService';
import { getGrupos } from '../../services/grupoService';
import { useEffect } from 'react';
import { createMatrimonio, getMatrimonio, updateMatrimonio } from '../../services/matrimoniosService';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const tipoMatrimonioOptions = [
  { label: 'Matrimonio Joven', value: 'JOVEN' },
  { label: 'Matrimonio Tradicional', value: 'TRADICIONAL' },
];

const estadoMaritalOptions = [
  { label: 'Sacramentado', value: 'SACRAMENTADO' },
  { label: 'Solo Civil', value: 'SOLO CIVIL' },
  { label: 'Unión de hecho', value: 'UNIÓN DE HECHO' }
];

const nacionalidadOptions = [
  { label: 'Paraguaya', value: 'PARAGUAYA' },
  { label: 'Argentina', value: 'ARGENTINA' },
  { label: 'Brasilera', value: 'BRASILERA' },
  { label: 'Otro', value: 'OTRO' },
];

const defaultValues = {
  mfc: {
    diocesis: null,
    base: null,
    grupo: null,
    fecha_ingreso: '',
    casa_retiro: '',
    fecha_encuentro: null,
    tipo_matrimonio: null,
    fecha_membresia: null,
  },
  matrimonio: {
    estado_marital: null,
    aniversario: '',
    parroquia_boda: '',
    direccion_domicilio: '',
    barrio_domicilio: '',
    ciudad_domicilio: '',
    estado_miembro: '',
  },
  esposo: {
    nombres: '',
    apellidos: '',
    documento: '',
    nacionalidad: null,
    fechaNacimiento: '',
    lugarNacimiento: '',
    profesion: '',
    lugarTrabajo: '',
    celular: '',
    telLaboral: '',
    email: '',
  },
  esposa: {
    nombres: '',
    apellidos: '',
    documento: '',
    nacionalidad: null,
    fechaNacimiento: '',
    lugarNacimiento: '',
    profesion: '',
    lugarTrabajo: '',
    celular: '',
    telLaboral: '',
    email: '',
  },
};


const MatrimonioForm = () => {
  const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm({
    defaultValues,
  });
  // Estados para manejar las opciones de diócesis, bases y grupos
  const [diocesisOptions, setDiocesisOptions] = useState([]);
  const [basesOptions, setBasesOptions] = useState([]);
  const [gruposOptions, setGruposOptions] = useState([]);

  // Use navigate y location como antes...
  const navigate = useNavigate();
  const location = useLocation();
  const { idMatrimonio } = location.state || {};

// Estado para mantener un indicador de si los valores por defecto ya se han establecido
  const [defaultValuesSet, setDefaultValuesSet] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchDiocesis();
        await fetchBases();
        await fetchGrupos();
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []);  

  // Solo se ejecuta cuando se obtiene un id de matrimonio cuando se quiere editar datos
  useEffect(() => {
    if (diocesisOptions.length > 0 && basesOptions.length > 0 && gruposOptions.length > 0 && idMatrimonio && !defaultValuesSet) {
      fetchMatrimonioData(idMatrimonio).then(dataMatrimonio => {
        setFormData(dataMatrimonio);
        setDefaultValuesSet(true);
      });
    }
  }, [diocesisOptions, basesOptions, gruposOptions, idMatrimonio, defaultValuesSet]);
  

  const commonStyles = {
    marginTop: '8px',
    marginBottom: '8px',
    width: '100%', 
  };

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      const diocesisData = response.diocesis.map((d) => ({
        label: d.nombre_diocesis,
        value: d.id_diocesis,
      }));
      setDiocesisOptions(diocesisData);

      // Establece el valor por defecto para la diócesis con la primera diócesis recibida
      if (diocesisData.length > 0) {
        // setValue('mfc.diocesis', diocesisData[0]);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };   

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      const basesData = response.bases.map((b) => ({
        label: b.nombre_base,
        value: b.id_base,
      }));
      setBasesOptions(basesData);

      // Establece el valor por defecto para la base con la primera base recibida
      if (basesData.length > 0) {
        // setValue('mfc.base', basesData[0]);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchGrupos = async (id_base = null, id_grupo = null) => {
    try {
      const response = await getGrupos(id_base, id_grupo);
      const gruposData = response.grupos.map((g) => ({
        label: g.nombre_grupo,
        value: g.id_grupo,
      }));
      setGruposOptions(gruposData);

      // Establece el valor por defecto para el grupo con el primer grupo recibido
      if (gruposData.length > 0) {
        // setValue('mfc.grupo', gruposData[0]);
      }

    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchMatrimonioData = async (id) => {
    try {
      const data = await getMatrimonio(id);
      return data;
    } catch (error) {
      console.error('Error al obtener los datos del matrimonio:', error);
    }
  };  

  const convertDate = (isoString) => {
    if (!isoString) {
      return null;
    }
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  };
  

  const setFormData = (data) => {

    const diocesisSeleccionada = diocesisOptions.find(d => d.value === data.grupos.bases.diocesis.id_diocesis);
    const baseSeleccionada = basesOptions.find(b => b.value === data.grupos.bases.id_base);
    const grupoSeleccionado = gruposOptions.find(g => g.value === data.id_grupo);
    const estadoMaritalSeleccionado = estadoMaritalOptions.find(e => e.value === data.estado_marital);
    const tipoMatrimonioSeleccionado = tipoMatrimonioOptions.find(t => t.value === data.tipo_mat);
    const nacionalidadEsposoSeleccionada = nacionalidadOptions.find(n => n.value === data.el_nacionalidad);
    const nacionalidadEsposaSeleccionada = nacionalidadOptions.find(n => n.value === data.ella_nacionalidad);
    
    reset({
      ...defaultValues,
      mfc:{
        diocesis: diocesisSeleccionada || null,
        base: baseSeleccionada || null,
        grupo: grupoSeleccionado || null,
        fecha_ingreso: convertDate(data.fecha_ingreso),
        casa_retiro: data.casa_retiro,
        fecha_encuentro: convertDate(data.fecha_encuentro),
        tipo_matrimonio: tipoMatrimonioSeleccionado || null,
        fecha_membresia: convertDate(data.fecha_membresia),
      },
      matrimonio: {
        estado_marital: estadoMaritalSeleccionado || null,
        aniversario: convertDate(data.aniversario),
        parroquia_boda: data.parroquia_boda,
        direccion_domicilio: data.direccion,
        barrio_domicilio: data.barrio,
        ciudad_domicilio: data.ciudad,
        // estado_miembro: data.id_estado_miembro,
      },
      esposo: {
        nombres: data.el_nombres,
        apellidos: data.el_apellidos,
        documento: data.el_cedula,
        nacionalidad: nacionalidadEsposoSeleccionada || null,
        fechaNacimiento: convertDate(data.el_fecha_nac),
        lugarNacimiento: data.el_lugar_nac,
        profesion: data.el_profesion,
        lugarTrabajo: data.el_lugar_trabajo,
        celular: data.el_cel,
        telLaboral: data.el_tel_laboral,
        email: data.el_correo,
      },
      esposa: {
        nombres: data.ella_nombres,
        apellidos: data.ella_apellidos,
        documento: data.ella_cedula,
        nacionalidad: nacionalidadEsposaSeleccionada || null,
        fechaNacimiento: convertDate(data.ella_fecha_nac),
        lugarNacimiento: data.ella_lugar_nac,
        profesion: data.ella_profesion,
        lugarTrabajo: data.ella_lugar_trabajo,
        celular: data.ella_cel,
        telLaboral: data.ella_tel_laboral,
        email: data.ella_correo,
      },
    })
  }

  const onSubmit = async (formData) => {
    let response;
    try {
      if (idMatrimonio) {
        // Modo de actualización
        response = await updateMatrimonio(idMatrimonio, formData);
        if (response.status === 201) {
          toast.success('Matrimonio actualizado exitosamente');
        } else {
          throw new Error('Error al actualizar el matrimonio');
        }
      } else {
        // Modo de creación
        response = await createMatrimonio(formData);
        if (response.status === 201) {
          toast.success('Matrimonio creado exitosamente');
        } else {
          throw new Error('Error al crear el matrimonio');
        }
      }
  
      navigate('/matrimonios');
    } catch (error) {
      console.error('Error al guardar matrimonio:', error);
      toast.error('Error al guardar matrimonio');
    }
  };

  const handleDiocesisChange = async (selectedDiocesis) => {
    // Obtener el ID de la diócesis seleccionada
    const id_diocesis = selectedDiocesis ? selectedDiocesis.value : null;
    // Filtrar las bases según la diócesis seleccionada
    await fetchBases(id_diocesis);
    setValue('mfc.base', null);
    setValue('mfc.grupo', null);
  };

  const handleBaseChange = async (selectedBase) => {
    // Obtener el ID de la base seleccionada
    const id_base = selectedBase ? selectedBase.value : null;
    // Filtrar los grupos por la base seleccionada
    await fetchGrupos(id_base);
    setValue('mfc.grupo', null);
  }
  

  const renderTextField = (name, label, required = false, type = 'text') => (
    <Grid item xs={12} sm={6} md={4}>
      <Controller
        name={name}
        control={control}
        rules={{ required: required && 'Este campo es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <TextField // Asegúrate de usar TextField estándar aquí
            {...field}
            type={type}
            fullWidth
            label={required ? `${label} *` : label}
            margin="normal"
            error={!!error}
            helperText={error ? error.message : null}
            InputLabelProps={type === 'date' ? { shrink: true } : undefined}
            sx={commonStyles}
          />
        )}
      />
    </Grid>
  );

  const renderAutocomplete = (name, label, required = false, options, defaultValue, onChangeHandler = null) => (
    <Grid item xs={12} sm={6} md={4}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ? defaultValue : null}
        rules={{ required: required && 'Este campo es obligatorio' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            options={options}
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={required ? `${label} *` : label}
                error={!!error}
                helperText={error ? error.message : null}
                required={required}
              />
            )}
            value={defaultValue || field.value}
            onChange={(_, data) => {
              field.onChange(data);
              if (onChangeHandler) {
                onChangeHandler(data);
              }
            }}
          />
        )}
      />
    </Grid>
  );

  return (
    <PageWrapper>
      <PageTitle title="Formulario Matrimonio"/>
      <PageContent>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper style={{ padding: 30 }}>

            {/* Sección datos del MFC */}
            <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>Datos del MFC</Typography>

            <Grid container spacing={2}>
              
              {renderAutocomplete('mfc.diocesis', 'Diócesis', true, diocesisOptions, null, handleDiocesisChange)}
              {renderAutocomplete('mfc.base', 'Base', true, basesOptions, null, handleBaseChange)}
              {renderAutocomplete('mfc.grupo', 'Grupo', true, gruposOptions)}
              {renderTextField('mfc.fecha_ingreso', 'Fecha de Ingreso al MFC - Kerygma', true, 'date')}
              {/* {renderTextField('mfc.casa_retiro', 'Casa de Retiro')} */}
              {renderTextField('mfc.fecha_encuentro', 'Fecha de Encuentro Conyugal', false, 'date')}
              {renderTextField('mfc.fecha_membresia', 'Fecha de Membresía Plena', false, 'date')}
              {renderAutocomplete('mfc.tipo_matrimonio', 'Tipo de Matrimonio', true, tipoMatrimonioOptions, defaultValues.mfc.tipo_matrimonio)}

            </Grid>

            {/* Sección datos del Matrimonio */}
            <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>Datos del Matrimonio</Typography>

            <Grid container spacing={2}>
              {renderAutocomplete('matrimonio.estado_marital', 'Estado Marital', true, estadoMaritalOptions, defaultValues.matrimonio.estado_marital)}
              {renderTextField('matrimonio.aniversario', 'Aniversario Boda', false, 'date')}
              {renderTextField('matrimonio.parroquia_boda', 'Parroquia de la Boda')}
              {renderTextField('matrimonio.ciudad_domicilio', 'Ciudad Domicilio')}
              {renderTextField('matrimonio.barrio_domicilio', 'Barrio Domicilio')}
              {renderTextField('matrimonio.direccion_domicilio', 'Dirección Domicilio')}
            </Grid>

            {/* Sección datos de la Esposa */}
            <Typography variant="h6" style={{ marginTop: 16 }}>
              Datos de la Esposa
            </Typography>
            <Grid container spacing={2}>
              {renderTextField('esposa.nombres', 'Nombres', true)}
              {renderTextField('esposa.apellidos', 'Apellidos', true)}
              {renderTextField('esposa.documento', 'Nro. de CI', true)}
              {renderAutocomplete('esposa.nacionalidad', 'Nacionalidad', true, nacionalidadOptions, defaultValues.esposa.nacionalidad )}
              {renderTextField('esposa.fechaNacimiento', 'Fecha de Nacimiento', true, 'date')}
              {renderTextField('esposa.lugarNacimiento', 'Lugar de Nacimiento - Ciudad', true)}
              {renderTextField('esposa.celular', 'Celular Personal')}
              {renderTextField('esposa.email', 'Correo Electrónico Personal', false, 'email')}
              {renderTextField('esposa.profesion', 'Profesión')}
              {renderTextField('esposa.lugarTrabajo', 'Lugar de Trabajo')}
              {renderTextField('esposa.telLaboral', 'Tel. Laboral')}
            </Grid>

            {/* Sección datos del Esposo */}
            <Typography variant="h6">Datos del Esposo</Typography>
            <Grid container spacing={2}>
              {renderTextField('esposo.nombres', 'Nombres', true)}
              {renderTextField('esposo.apellidos', 'Apellidos', true)}
              {renderTextField('esposo.documento', 'Nro. de CI', true)}
              {renderAutocomplete('esposo.nacionalidad', 'Nacionalidad', true, nacionalidadOptions, defaultValues.esposo.nacionalidad )}
              {renderTextField('esposo.fechaNacimiento', 'Fecha de Nacimiento', true, 'date')}
              {renderTextField('esposo.lugarNacimiento', 'Lugar de Nacimiento - Ciudad', true)}
              {renderTextField('esposo.celular', 'Celular Personal')}
              {renderTextField('esposo.email', 'Correo Electrónico Personal', false, 'email')}
              {renderTextField('esposo.profesion', 'Profesión')}
              {renderTextField('esposo.lugarTrabajo', 'Lugar de Trabajo')}
              {renderTextField('esposo.telLaboral', 'Tel. Laboral')}
            </Grid>
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              * Campos obligatorios
            </Typography>
            <Grid container style={{ marginTop: 16 }} justifyContent="flex-end">
              <Button
                type="button"
                onClick={() => navigate('/matrimonios')}
                style={{ marginRight: 8 }}
                variant='outlined'
              >
                Cancelar
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Guardar
              </Button>
            </Grid>
          </Paper>
        </form>

      </PageContent>
    </PageWrapper>
  );
};

export default MatrimonioForm;
