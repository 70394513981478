import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';

const archivos = [
  { nombre: 'Reporte de Datos del Sistema', tipo: 'Sistema', ruta: '/files/Reporte-Datos-Sistema-MFC-Julio-2024.pdf' },
  { nombre: 'Novedades del Sistema', tipo: 'Sistema', ruta: '/files/Novedades-Sistema-MFC.pdf' },
  { nombre: 'Formulario para Reportes del Sistema', tipo: 'Sistema', ruta: 'https://forms.gle/T91REDsg3asjc6q49' },
  { nombre: 'Vídeos explicativos sobre uso del Sistema', tipo: 'Sistema', ruta: 'https://drive.google.com/drive/folders/1XHvdOhCqjKLGAxiPDnlLq2ehGlaO1zEk?usp=drive_link' },
  { nombre: 'Estatutos del MFC Paraguay', tipo: 'General', ruta: '/files/Estatuto.pdf' },
  { nombre: 'Reglamento del MFC Paraguay', tipo: 'General', ruta: '/files/Reglamento.pdf' },
  { nombre: 'Formulario Seguimiento S-01', tipo: 'Matrimonio', ruta: '/files/FormularioS-01.pdf' },
  { nombre: 'Formulario Seguimiento S-02', tipo: 'Matrimonio', ruta: '/files/FormularioS-02.pdf' },
  { nombre: 'Formulario Seguimiento S-03', tipo: 'Matrimonio', ruta: '/files/FormularioS-03.pdf' },
  { nombre: 'Formulario Seguimiento S-04', tipo: 'Matrimonio', ruta: '/files/FormularioS-04.pdf' },
  { nombre: 'Formulario Seguimiento S-05', tipo: 'Matrimonio', ruta: '/files/FormularioS-05.pdf' },
  { nombre: 'Formulario Seguimiento S-06', tipo: 'Matrimonio', ruta: '/files/FormularioS-06.pdf' },
  { nombre: 'Formulario Seguimiento S-07', tipo: 'Matrimonio', ruta: '/files/FormularioS-07.pdf' },
  { nombre: 'Formulario Seguimiento S-08', tipo: 'Matrimonio', ruta: '/files/FormularioS-08.pdf' },
  { nombre: 'Formulario Seguimiento S-09', tipo: 'Matrimonio', ruta: '/files/FormularioS-09.pdf' },
  { nombre: 'Formulario Seguimiento S-10', tipo: 'Matrimonio', ruta: '/files/FormularioS-10.pdf' },
  { nombre: 'Formulario Seguimiento SJ-01', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-01.pdf' },
  { nombre: 'Formulario Seguimiento SJ-02', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-02.pdf' },
  { nombre: 'Formulario Seguimiento SJ-03', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-03.pdf' },
  { nombre: 'Formulario Seguimiento SJ-04', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-04.pdf' },
  { nombre: 'Formulario Seguimiento SJ-05', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-05.pdf' },
  { nombre: 'Formulario Seguimiento SJ-06', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-06.pdf' },
  { nombre: 'Formulario Seguimiento SJ-07', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-07.pdf' },
  { nombre: 'Formulario Seguimiento SJ-08', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-08.pdf' },
  { nombre: 'Formulario Seguimiento SJ-09', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-09.pdf' },
  { nombre: 'Formulario Seguimiento SJ-10', tipo: 'Jóvenes', ruta: '/files/FormularioSJ-10.pdf' },


  // Agrega más archivos según sea necesario
];

function Repositorio() {
  return (
    <PageWrapper>
      <PageTitle title="Repositorio de documentos" />
      <PageContent>
        <TableContainer component={Paper} style={{ maxWidth: '1000px', maxHeight: '550px', margin: '0 auto' }}>
          <Table>
            <TableHead>
              {/* Encabezados en negrita */}
              <TableRow>  
                <TableCell style={{ fontWeight: 'bold' }}>Nombre del archivo</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Tipo de archivo</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Descargar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivos.map((archivo, index) => (
                <TableRow key={index}>
                  <TableCell>{archivo.nombre}</TableCell>
                  <TableCell>{archivo.tipo}</TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      startIcon={<GetAppIcon />} 
                      href={archivo.ruta} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      Descargar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageWrapper>
  );
}

export default Repositorio;
