
export const convertDate = (isoString) => {
    if (!isoString) {
      return null;
    }
    const date = new Date(isoString);
    // Obtiene el día, mes y año de la fecha
    const day = date.getDate().toString().padStart(2, '0'); // Asegura dos dígitos
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Asegura dos dígitos, +1 porque getMonth() retorna 0-11
    const year = date.getFullYear();
    // Formatea la fecha en dd-mm-YYYY
    return `${day}-${month}-${year}`;
  };
  
  export const getToday = () => {
    return new Date().toLocaleDateString('en-CA'); // Formato YYYY-MM-DD
  };