import React from 'react';
import Typography from '@mui/material/Typography';
import BreadcrumbsComponent from '../../components/breadcrumbs/BreadcrumbsComponent';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';

function Profile() {
  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const breadcrumbs = [
    { label: 'Principal', href: '/', onClick: handleClick },
    { label: 'Vista', href: '/', onClick: handleClick },
    { label: 'Opción' }, // Puedes omitir el "href" si no quieres un enlace
  ];

  return (
    <PageWrapper>

      <BreadcrumbsComponent breadcrumbs={breadcrumbs} />

      <PageTitle title="Mi Perfil"/>

      <PageContent>
        <Typography variant="h5" align="center">
          Contenido
        </Typography>
      </PageContent>

    </PageWrapper>
  );
}

export default Profile;
