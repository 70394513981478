import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const descargarCuadranteMatrimonios = async (id_diocesis = null, id_base = null, id_grupo = null, id_estado = null) => {
    try {
        const response = await axios.get(`${API_URL}/reportes/cuadrantes-matrimonios`, {
            params: { 
                id_diocesis,
                id_base,
                id_grupo,
                id_estado
            },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const obtenerCantidadMatrimonios = async () => {
    try {
        const response = await axios.get(`${API_URL}/reportes/cantidad-matrimonios`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}