import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageContent from '../../components/pageContent/PageContent';
import PageTitle from '../../components/pageContent/PageTitle';
import Autocomplete from '@mui/lab/Autocomplete';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getJoven, createJoven, updateJoven } from '../../services/jovenesService';
import { getDiocesis } from '../../services/diocesisService';
import { getBases } from '../../services/baseService';
import { getGruposJovenes } from '../../services/grupoJovenesService';

const nacionalidadOptions = [
  { label: 'Paraguaya', value: 'PARAGUAYA' },
  { label: 'Argentina', value: 'ARGENTINA' },
  { label: 'Brasilera', value: 'BRASILERA' },
  { label: 'Otro', value: 'OTRO' },
];

const padresPertenecenMFCOptions = [
  { label: 'Sí', value: true },
  { label: 'No', value: false },
];

const defaultValues = {
    mfc: {
      diocesis: null,
      base: null,
      grupo: null,
      fecha_ingreso: '',
      casa_retiro: '',
      fecha_membresia: null,
    },
    joven: {
      nombres: '',
      apellidos: '',
      documento: '',
      nacionalidad: null,
      fechaNacimiento: '',
      lugarNacimiento: '',
      profesion: '',
      lugarTrabajo: '',
      celular: '',
      telLaboral: '',
      email: '',
    },
    familia: {
      direccionDomicilio: '',
      barrioDomicilio: '',
      ciudadDomicilio: '',
      nombrePadre: '',
      telPadre: '',
      nombreMadre: '',
      telMadre: '',
      padresPertenecenMFC: null,
    },
  };


const JovenForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { idJoven } = location.state || {};

  // Estados para manejar las opciones de diócesis, bases y grupos
  const [diocesisOptions, setDiocesisOptions] = useState([]);
  const [basesOptions, setBasesOptions] = useState([]);
  const [gruposOptions, setGruposOptions] = useState([]);

  const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm({
    defaultValues,
  });

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchDiocesis();
        await fetchBases();
        await fetchGrupos();
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []); 

  useEffect(() => {
    if (idJoven) {
      if (diocesisOptions.length > 0 && basesOptions.length > 0 && gruposOptions.length > 0 && idJoven){
        fetchJoven(idJoven).then((dataJoven) => {
          setFormData(dataJoven);
        });
      }
    }
  }, [diocesisOptions, basesOptions, gruposOptions, idJoven]);

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      const diocesisData = response.diocesis.map((d) => ({
        label: d.nombre_diocesis,
        value: d.id_diocesis,
      }));
      setDiocesisOptions(diocesisData);

      // Establece el valor por defecto para la diócesis con la primera diócesis recibida
      if (diocesisData.length > 0) {
        // setValue('mfc.diocesis', diocesisData[0]);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  }; 

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      const basesData = response.bases.map((b) => ({
        label: b.nombre_base,
        value: b.id_base,
      }));
      setBasesOptions(basesData);

      // Establece el valor por defecto para la base con la primera base recibida
      if (basesData.length > 0) {
        // setValue('mfc.base', basesData[0]);
      }
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchGrupos = async (id_base = null, id_grupo = null) => {
    try {
      const response = await getGruposJovenes(id_base, id_grupo);
      const gruposData = response.grupos.map((g) => ({
        label: g.nombre_grupo,
        value: g.id_grupo_joven,
      }));
      setGruposOptions(gruposData);

      // Establece el valor por defecto para el grupo con el primer grupo recibido
      if (gruposData.length > 0) {
        // setValue('mfc.grupo', gruposData[0]);
      }

    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };


    const commonStyles = {
        marginTop: '8px',
        marginBottom: '8px',
        width: '100%', 
    };

    const handleDiocesisChange = async (selectedDiocesis) => {
      // Obtener el ID de la diócesis seleccionada
      const id_diocesis = selectedDiocesis ? selectedDiocesis.value : null;
      // Filtrar las bases según la diócesis seleccionada
      await fetchBases(id_diocesis);
      setValue('mfc.base', null);
      setValue('mfc.grupo', null);
    };

    const handleBaseChange = async (selectedBase) => {
      // Obtener el ID de la base seleccionada
      const id_base = selectedBase ? selectedBase.value : null;
      // Filtrar los grupos por la base seleccionada
      await fetchGrupos(id_base);
      setValue('mfc.grupo', null);
    }

    const renderTextField = (name, label, required = false, type = 'text') => (
        <Grid item xs={12} sm={6} md={4}>
          <Controller
            name={name}
            control={control}
            rules={{ required: required && 'Este campo es obligatorio' }}
            render={({ field, fieldState: { error } }) => (
              <TextField // Asegúrate de usar TextField estándar aquí
                {...field}
                type={type}
                fullWidth
                label={required ? `${label} *` : label}
                margin="normal"
                error={!!error}
                helperText={error ? error.message : null}
                InputLabelProps={type === 'date' ? { shrink: true } : undefined}
                sx={commonStyles}
              />
            )}
          />
        </Grid>
      );
    
    const renderAutocomplete = (name, label, required = false, options, defaultValue, onChangeHandler = null) => (
      <Grid item xs={12} sm={6} md={4}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          rules={{ required: required && 'Este campo es obligatorio' }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              options={options}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={required ? `${label} *` : label}
                  error={!!error}
                  helperText={error ? error.message : null}
                  required={required}
                />
              )}
              value={defaultValue || field.value}
              onChange={(_, data) => {
                field.onChange(data);
                if (onChangeHandler) {
                  onChangeHandler(data);
                }
              }}
            />
          )}
        />
      </Grid>
    ); 
    
    const fetchJoven = async (id) => {
      try {
        const data = await getJoven(id);
        return data;
      } catch (error) {
        console.error('Error al obtener los datos del joven:', error);
      }
    }; 

    const onSubmit = async (formData) => {
      let response;
      try {
        if (idJoven) {
          // Modo de actualización
          response = await updateJoven(idJoven, formData);
          if (response.status === 201) {
            toast.success('Joven actualizado exitosamente');
          } else {
            throw new Error('Error al actualizar el matrimonio');
          }
        } else {
          // Modo de creación
          response = await createJoven(formData);
          if (response.status === 201) {
            toast.success('Joven creado exitosamente');
          } else {
            throw new Error('Error al crear el matrimonio');
          }
        }
    
        navigate('/jovenes');
      } catch (error) {
        console.error('Error al guardar datos del joven:', error);
        toast.error('Error al guardar datos del joven');
      }
    }

    const convertDate = (isoString) => {
      if (!isoString) {
        return null;
      }
      const date = new Date(isoString);
      return date.toISOString().split('T')[0];
    };

  const setFormData = (data) => {

    const diocesisSeleccionada = diocesisOptions.find(d => d.value === data.grupos_jovenes.bases.diocesis.id_diocesis);
    const baseSeleccionada = basesOptions.find(b => b.value === data.grupos_jovenes.bases.id_base);
    const grupoSeleccionado = gruposOptions.find(g => g.value === data.id_grupo_joven);     
    const nacionalidadSeleccionada = nacionalidadOptions.find(n => n.value === data.nacionalidad);
    const padresPertenecenMFCSeleccionado = padresPertenecenMFCOptions.find(p => p.value === data.padres_pertenecen_mfc);
    
    reset({
      ...defaultValues,
      mfc:{
        diocesis: diocesisSeleccionada || null,
        base: baseSeleccionada || null,
        grupo: grupoSeleccionado || null,
        fecha_ingreso: convertDate(data.fecha_inicio),
        casa_retiro: '',
        fecha_membresia: convertDate(data.fecha_membresia),
      },
      joven: {
        nombres: data.nombres,
        apellidos: data.apellidos,
        documento: data.cedula,
        nacionalidad: nacionalidadSeleccionada || null,
        fechaNacimiento: convertDate(data.fecha_nac),
        lugarNacimiento: data.lugar_nacimiento,
        profesion: data.profesion,
        lugarTrabajo: data.lugar_trabajo,
        celular: data.cel,
        telLaboral: data.tel_laboral,
        email: data.correo,
      },
      familia: {
        direccionDomicilio: data.calle,
        barrioDomicilio: data.barrio,
        ciudadDomicilio: data.ciudad,
        nombrePadre: data.nombre_padre,
        telPadre: data.tel_padre,
        nombreMadre: data.nombre_madre,
        telMadre: data.tel_madre,
        padresPertenecenMFC: padresPertenecenMFCSeleccionado || null,
      },
    })
  }

    return (
        <PageWrapper>
            <PageTitle title="Formulario Joven"/>
            <PageContent>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Paper style={{ padding: 30 }}> 
                    <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>Datos del MFC</Typography>

                    <Grid container spacing={2}>
                        {renderAutocomplete('mfc.diocesis', 'Diócesis', true, diocesisOptions, null, handleDiocesisChange)}
                        {renderAutocomplete('mfc.base', 'Base', true, basesOptions, null, handleBaseChange)}
                        {renderAutocomplete('mfc.grupo', 'Grupo', true, gruposOptions)}
                        {renderTextField('mfc.fecha_ingreso', 'Fecha de Ingreso al MFC', true, 'date')}
                        {renderTextField('mfc.fecha_membresia', 'Fecha de Membresía Plena', false, 'date')}
                    </Grid>

                    <Typography variant="h6" style={{ marginTop: 16 }}>Datos del Joven</Typography>

                    <Grid container spacing={2}>
                        {renderTextField('joven.nombres', 'Nombres')}
                        {renderTextField('joven.apellidos', 'Apellidos')}
                        {renderTextField('joven.documento', 'Nro. de CI')}
                        {renderAutocomplete('joven.nacionalidad', 'Nacionalidad', true, nacionalidadOptions, defaultValues.joven.nacionalidad)}
                        {renderTextField('joven.fechaNacimiento', 'Fecha de Nacimiento', true, 'date')}
                        {renderTextField('joven.lugarNacimiento', 'Lugar de Nacimiento - Ciudad', true)}
                        {renderTextField('joven.celular', 'Celular Personal')}
                        {renderTextField('joven.email', 'Correo Electrónico Personal', false, 'email')}
                        {renderTextField('joven.profesion', 'Profesión')}
                        {renderTextField('joven.lugarTrabajo', 'Lugar de Trabajo')}
                        {renderTextField('joven.telLaboral', 'Tel. Laboral')}
                    </Grid>

                    <Typography variant="h6" style={{ marginTop: 16 }}>Datos de la Familia</Typography>

                    <Grid container spacing={2}>
                        {renderTextField('familia.nombrePadre', 'Nombre Padre')}
                        {renderTextField('familia.telPadre', 'Cel. Padre')}
                        {renderTextField('familia.nombreMadre', 'Nombre Madre')}
                        {renderTextField('familia.telMadre', 'Cel. Madre')}
                        {renderAutocomplete('familia.padresPertenecenMFC', 'Padres pertenecen al MFC', true, padresPertenecenMFCOptions, defaultValues.familia.padresPertenecenMFC)}
                        {renderTextField('familia.ciudadDomicilio', 'Ciudad Domicilio')}
                        {renderTextField('familia.barrioDomicilio', 'Barrio Domicilio')}
                        {renderTextField('familia.direccionDomicilio', 'Dirección Domicilio - Calle')}
                    </Grid>
                    <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
                      * Campos obligatorios
                    </Typography>
                    <Grid container style={{ marginTop: 16 }} justifyContent="flex-end">
                      <Button
                        type="button"
                        onClick={() => navigate('/jovenes')}
                        style={{ marginRight: 8 }}
                        variant='outlined'
                      >
                        Cancelar
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        Guardar
                      </Button>
                    </Grid>                    

                </Paper>
            </form>

            </PageContent>
        </PageWrapper>
    )
}

export default JovenForm;