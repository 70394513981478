import React from 'react';
import Box from '@mui/material/Box';

function PageWrapper({ children }) {
  return (
    <Box
        component="main"
        sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        m: 0,
        pt: 3,
        pl: 9,
        pr: 2,
        backgroundColor: 'grey.100'
        }}
    >
      {children}
    </Box>
  );
}

export default PageWrapper;
