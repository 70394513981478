import PublicIcon from '@mui/icons-material/Public';
import ChurchIcon from '@mui/icons-material/Church';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SavingsIcon from '@mui/icons-material/Savings';
import BungalowIcon from '@mui/icons-material/Bungalow';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import useStore from '../../store/store';

// Niveles de acceso
// 1 - Grupo
// 2 - Base
// 3 - Diócesis
// 4 - Nacional

const menuItems = [
    {
        text: 'Diócesis',
        icon: <SouthAmericaIcon/>,
        path: '/diocesis',
        accessLevels: [4]
    },
    {
        text: 'Bases Parroquiales',
        icon: <ChurchIcon/>,
        path: '/bases',
        accessLevels: [3, 4]
    },
    {
        text: 'Grupos',
        icon: <GroupWorkIcon/>,
        path: '/grupos',
        accessLevels: [2, 3, 4]
    },
    {
        text: 'Matrimonios',
        icon: <FamilyRestroomIcon />,
        path: '/matrimonios',
        accessLevels: [2, 3, 4]
    },
    {
        text: 'Jóvenes',
        icon: <Diversity3Icon />,
        path: '/jovenes',
        accessLevels: [2, 3, 4]
    },
    {
        text: 'Cursos',
        icon: <ImportContactsIcon />,
        path: '/cursos', 
        accessLevels: [2, 3, 4] 
    },
    // {
    //     text: 'Aportes',
    //     icon: <SavingsIcon />,
    //     path: '/aportes',  
    // },
    {
        text: 'Reportes',
        icon: <EqualizerIcon />,
        path: '/reportes',  
        accessLevels: [2, 3, 4] 
    },
    {
        text: 'Repositorio',
        icon: <PictureAsPdfIcon />,
        path: '/repositorio',
        accessLevels: [2, 3, 4] 
    },
    {
        text: 'Administración',
        icon: <AdminPanelSettingsIcon />,
        path: '/administracion',
        accessLevels: [3, 4]
    },
];

// Hook para obtener y filtrar el menú
const useFilteredMenuItems = () => {
    const { nivelAcceso } = useStore();
    return menuItems.filter(item => item.accessLevels.includes(nivelAcceso));
};

export default useFilteredMenuItems;
